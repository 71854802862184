<template>
    <!-- site content -->
    <div class="main-page pt-3">
        <main class="main-container">
            <div class="container custom-container">
                <section>
                    <div class="table-box p-3">
                        <div id="notfound">
                            <div class="notfound-bg"></div>
                            <div class="notfound">
                                <div class="notfound-404">
                                    <h1>404</h1>
                                </div>
                                <h2>we are sorry, but the page you requested was not found</h2>
                                <router-link :to="'/dashboard'" class="home-btn">Go Home</router-link>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </main>

        <BottomFooter></BottomFooter>
    </div>
    <!-- /site content end-->
</template>

<script>
    import BottomFooter from "@/views/layouts/auth/common/BottomFooter";

    export default {
        name: "404",
        components: {BottomFooter}
    }
</script>

<style scoped>

</style>